import { FormikValues } from 'formik';

interface BatchInfo {
  batch_id: string;
  manufacturing_date: string;
  expiration_date: string;
  num_bottles: number;
}

function formatDateToISO(dateStr: string): string | null {
  const [day, month, year] = dateStr.trim().split('/');
  if (!day || !month || !year) return null;
  const fullYear = parseInt(year) < 50 ? `20${year}` : `19${year}`;
  return `${fullYear}-${month}-${day}`;
}

export function InvoiceToBatch(text: string): FormikValues[] {
  const batches: BatchInfo[] = [];
  
  // Split the text into potential batch sections
  const sections = text.split('Batch:').slice(1); // slice(1) to skip empty first element

  for (const section of sections) {
    const batchInfo: BatchInfo = {
      batch_id: '',
      manufacturing_date: '',
      expiration_date: '',
      num_bottles: 0
    };
    
    // Extract batch ID (alphanumeric)
    const batchMatch = section.match(/\s*([A-Za-z0-9]+)/i);
    if (batchMatch) {
      batchInfo.batch_id = batchMatch[1].trim();
    }
    
    // Extract manufacturing date (DD/MM/YY) and convert to YYYY-MM-DD
    const mfgMatch = section.match(/Mfg\s*Dte:\s*(\d{2}\/\d{2}\/\d{2})\b/i);
    if (mfgMatch) {
      const formattedDate = formatDateToISO(mfgMatch[1]);
      batchInfo.manufacturing_date = formattedDate || '';
    }
    
    // Extract expiration date (DD/MM/YY) and convert to YYYY-MM-DD
    const expMatch = section.match(/Exp\s*Dte:\s*(\d{2}\/\d{2}\/\d{2})\b/i);
    if (expMatch) {
      const formattedDate = formatDateToISO(expMatch[1]);
      batchInfo.expiration_date = formattedDate || '';
    }
    
    // Extract quantity (number with optional thousands separator)
    const qtyMatch = section.match(/Qty:\s*(?:KG\s+)?([\d,]+(?:\.\d+)?)\s*PC/i);
    if (qtyMatch) {
      batchInfo.num_bottles = parseInt(qtyMatch[1].replace(/[,.]/g, ''));
    }
    
    if (batchInfo.batch_id) {
      batches.push(batchInfo);
    }
  }
  
  return batches as FormikValues[];
}